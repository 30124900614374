
export default {
  props: [''],
  data() {
    return {
      currentDateYear: '',
      currentDateYearMonth: ''
    };
  },
  mounted() {
    this.updateDate();
  },

  methods: {
    updateDate() {
      const optionYear = { year: 'numeric'}; 
      const optionsYearMonth = { year: 'numeric', month: 'long'}; 
      this.currentDateYear = new Date().toLocaleDateString('fr-FR', optionYear);
      this.currentDateYearMonth = new Date().toLocaleDateString('fr-FR', optionsYearMonth);
    },
  },

  computed: {
    instances() {
      return this.$store.state.instances;
    },
    instance() {
      const instance = this.$store.state.instances.filter((instance) => {
        return instance.attributes.subFolderName == this.$store.state.params.campusHP
      })[0];
      return instance;
    },
    strapiBaseUri() {
      return process.env.strapiBaseUri;
    },
    campusInfo() {
      return this.$store.state.campusInfo;
    },
    detailInfo() {
      return this.$store.state.detailInfo;
    },
    campusHP() {
      return this.$route.params.campusHP
    },
    
  },

}
