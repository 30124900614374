import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _228353ce = () => interopDefault(import('../pages/404-error.vue' /* webpackChunkName: "pages/404-error" */))
const _7590b5a8 = () => interopDefault(import('../pages/candidats.vue' /* webpackChunkName: "pages/candidats" */))
const _3ef0ba68 = () => interopDefault(import('../pages/contacter.vue' /* webpackChunkName: "pages/contacter" */))
const _86993bf0 = () => interopDefault(import('../pages/icfaDetails.vue' /* webpackChunkName: "pages/icfaDetails" */))
const _5599ddc4 = () => interopDefault(import('../pages/jpo.vue' /* webpackChunkName: "pages/jpo" */))
const _5dab0bf9 = () => interopDefault(import('../pages/mentionsLegales.vue' /* webpackChunkName: "pages/mentionsLegales" */))
const _8a4d9186 = () => interopDefault(import('../pages/offreAlternanceTous.vue' /* webpackChunkName: "pages/offreAlternanceTous" */))
const _f5c83394 = () => interopDefault(import('../pages/politiqueDeConfidentialite.vue' /* webpackChunkName: "pages/politiqueDeConfidentialite" */))
const _5bc7af48 = () => interopDefault(import('../pages/sf.vue' /* webpackChunkName: "pages/sf" */))
const _0a84e96d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _486d13eb = () => interopDefault(import('../pages/news/_news.vue' /* webpackChunkName: "pages/news/_news" */))
const _03a54264 = () => interopDefault(import('../pages/_campusHP/index.vue' /* webpackChunkName: "pages/_campusHP/index" */))
const _6a594771 = () => interopDefault(import('../pages/_campusHP/campusDetail/index.vue' /* webpackChunkName: "pages/_campusHP/campusDetail/index" */))
const _e4848042 = () => interopDefault(import('../pages/_campusHP/contacter.vue' /* webpackChunkName: "pages/_campusHP/contacter" */))
const _0f6dd4e4 = () => interopDefault(import('../pages/_campusHP/lesAtouts.vue' /* webpackChunkName: "pages/_campusHP/lesAtouts" */))
const _0091fc84 = () => interopDefault(import('../pages/_campusHP/offreAlternance/index.vue' /* webpackChunkName: "pages/_campusHP/offreAlternance/index" */))
const _fac1a916 = () => interopDefault(import('../pages/_campusHP/jpo/Journee-Portes-Ouvertes-4.vue' /* webpackChunkName: "pages/_campusHP/jpo/Journee-Portes-Ouvertes-4" */))
const _ad2b5140 = () => interopDefault(import('../pages/_campusHP/landing/landingPage.vue' /* webpackChunkName: "pages/_campusHP/landing/landingPage" */))
const _4627cc24 = () => interopDefault(import('../pages/_campusHP/jpo/_jpo.vue' /* webpackChunkName: "pages/_campusHP/jpo/_jpo" */))
const _432487a4 = () => interopDefault(import('../pages/_campusHP/landing/_landing.vue' /* webpackChunkName: "pages/_campusHP/landing/_landing" */))
const _043dd9b2 = () => interopDefault(import('../pages/_campusHP/offreAlternance/_offre.vue' /* webpackChunkName: "pages/_campusHP/offreAlternance/_offre" */))
const _6bd844c4 = () => interopDefault(import('../pages/_campusHP/_diploma/index.vue' /* webpackChunkName: "pages/_campusHP/_diploma/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404-error",
    component: _228353ce,
    name: "404-error"
  }, {
    path: "/candidats",
    component: _7590b5a8,
    name: "candidats"
  }, {
    path: "/contacter",
    component: _3ef0ba68,
    name: "contacter"
  }, {
    path: "/icfaDetails",
    component: _86993bf0,
    name: "icfaDetails"
  }, {
    path: "/jpo",
    component: _5599ddc4,
    name: "jpo"
  }, {
    path: "/mentionsLegales",
    component: _5dab0bf9,
    name: "mentionsLegales"
  }, {
    path: "/offreAlternanceTous",
    component: _8a4d9186,
    name: "offreAlternanceTous"
  }, {
    path: "/politiqueDeConfidentialite",
    component: _f5c83394,
    name: "politiqueDeConfidentialite"
  }, {
    path: "/sf",
    component: _5bc7af48,
    name: "sf"
  }, {
    path: "/",
    component: _0a84e96d,
    name: "index"
  }, {
    path: "/news/:news?",
    component: _486d13eb,
    name: "news-news"
  }, {
    path: "/:campusHP",
    component: _03a54264,
    name: "campusHP"
  }, {
    path: "/:campusHP/campusDetail",
    component: _6a594771,
    name: "campusHP-campusDetail"
  }, {
    path: "/:campusHP/contacter",
    component: _e4848042,
    name: "campusHP-contacter"
  }, {
    path: "/:campusHP/lesAtouts",
    component: _0f6dd4e4,
    name: "campusHP-lesAtouts"
  }, {
    path: "/:campusHP/offreAlternance",
    component: _0091fc84,
    name: "campusHP-offreAlternance"
  }, {
    path: "/:campusHP/jpo/Journee-Portes-Ouvertes-4",
    component: _fac1a916,
    name: "campusHP-jpo-Journee-Portes-Ouvertes-4"
  }, {
    path: "/:campusHP/landing/landingPage",
    component: _ad2b5140,
    name: "campusHP-landing-landingPage"
  }, {
    path: "/:campusHP/jpo/:jpo?",
    component: _4627cc24,
    name: "campusHP-jpo-jpo"
  }, {
    path: "/:campusHP/landing/:landing?",
    component: _432487a4,
    name: "campusHP-landing-landing"
  }, {
    path: "/:campusHP/offreAlternance/:offre",
    component: _043dd9b2,
    name: "campusHP-offreAlternance-offre"
  }, {
    path: "/:campusHP/:diploma",
    component: _6bd844c4,
    name: "campusHP-diploma"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
